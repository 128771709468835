<template>
    <router-view />
    <BackToTop />
</template>

<script>
import BackToTop from './components/Layouts/BackToTop'

export default {
    name: 'App',
    components: {
        BackToTop,
    },
    data() {
        return {
        }
    },
    mounted() {
    }
}
</script>