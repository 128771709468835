<!--<template>
    <div class="blog-area pt-100 pb-75">
        <div class="container">
            <div class="section-title">
                <span>Blog</span>
                <h2>Spannende Themen rund um den Bereich <b>IT</b></h2>
            </div>

            <div class="blog-slides">
                <carousel
                    :autoplay="5000"
                    :settings='settings' 
                    :wrap-around="true"
                    :breakpoints='breakpoints'
                >
                    <Slide v-for="slide in carouselItems" :key="slide.id">
                        <div class="blog-card">
                            <div class="row align-items-center">
                                <div class="col-lg-6">
                                    <div class="blog-image">
                                        <router-link :to="slide.btnLink">
                                            <img :src="slide.image" alt="image">
                                        </router-link>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="blog-content">
                                        <div class="date">{{slide.date}}</div>
                                        <h3>
                                            <router-link :to="slide.btnLink">
                                                {{slide.title}}
                                            </router-link>
                                        </h3>
                                        <p>{{slide.desc}}</p>
                                        <router-link :to="slide.btnLink" class="blog-btn">
                                            {{slide.btnText}}
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slide>

                    <template #addons>
                        <Navigation />
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>-->

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'Blog',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                image: require('../../assets/images/blog/blog-1.jpg'),
                date: '17. Januar 2023',
                title: 'Sicherheitslücke: Mitarbeiter',
                desc: 'IT-Sicherheit hat in Unternehmen aller Größen und Branchen eine entscheidende Bedeutung. Eine der größten Bedrohungen für die IT-Sicherheit ist jedoch das menschliche Element..',
                btnLink: '/single-blog-1',
                btnText: 'Weiterlesen',
            },
            {
                id: 2,
                image: require('../../assets/images/blog/blog-2.jpg'),
                date: '23. Dezember 2022',
                title: 'Teure Backups? Ohne ist oft teurer!',
                desc: 'Datenverlust kann für Unternehmen existenzbedrohend sein. Ob durch menschliches Versagen, Naturkatastrophen oder Cyber-Angriffe, Unternehmen müssen sich auf das Schlimmste vorbereiten, um sicherzustellen, dass ihre wichtigen Daten sicher..',
                btnLink: '/single-blog-1',
                btnText: 'Weiterlesen',
            },
            {
                id: 3,
                image: require('../../assets/images/blog/blog-3.jpg'),
                date: '11. November 2022',
                title: 'Alarm Systeme ohne großen Aufwand',
                desc: 'Smarte Alarmsysteme bieten Unternehmen eine effektive Möglichkeit, ihr Büro zu schützen und ihre Mitarbeiter sowie ihre wertvollen Güter sicherzustellen. Diese fortschrittlichen Systeme nutzen moderne Technologien wie..',
                btnLink: '/single-blog-1',
                btnText: 'Weiterlesen',
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'left',
			},
            768: {
                itemsToShow: 2,
                snapAlign: 'left',
            },
            1200: {
                itemsToShow: 2,
                snapAlign: 'left',
            },
        },
    }),
})
</script>