<template>
    <div id="services" class="services-area margin-zero ptb-100">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-12">
                    <div class="services-section-content" data-aos="fade-down" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                        <span>Dienstleistungen</span>
                        <h3>Das können wir!</h3>
                        <p>Durch Jahrelange Erfahrung hat sich ein Portfolio ergeben was sich sehen lassen kann.</p>
                        <div class="services-section-btn">
                            <a href="#contact" class="default-btn">
                                Kontakt aufnehmen
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-8 col-md-12">
                    <div class="services-slides">
                        <carousel
                            :autoplay="5000"
                            :wrap-around="true"
                            :settings='settings' 
                            :breakpoints='breakpoints'
                        >
                            <Slide v-for="slide in carouselItems" :key="slide.id">
                                <div class="services-item">
                                    <div class="services-image">
                                      <img :src="slide.image" alt="image">
                                    </div>
                                    <div class="services-content">
                                        <h3>
                                          {{slide.title}}
                                        </h3>
                                        <p>{{slide.desc}}</p>
                                    </div>
                                </div>
                            </Slide>

                            <template #addons>
                                <Pagination />
                            </template>
                        </carousel>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Pagination  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'Services',
    components: {
        Carousel,
        Slide,
        Pagination,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                image: require('../../assets/images/services/services-it-infra.png'),
                title: 'IT-Infrastruktur',
                desc: 'Als IT-Dienstleister bieten wir Dir eine ganzheitliche IT-Infrastruktur-Betreuung. Dies umfasst die Ausarbeitung eines Konzeptes anhand Deiner individuellen Vorstellungen, die Bereitstellung von Hardware und Software sowie die Installation und fortlaufende Wartung unserer Systeme.',
                link: '/services-details',
                btnText: 'View More',
            },
            {
                id: 2,
                image: require('../../assets/images/services/services-software.png'),
                title: 'Web-, App-, und Softwareentwicklung',
                desc: 'Konzept, Design, Entwicklung und Support aus einer Hand. Apps und weitere Softwareprodukte können wir auch!',
                link: '/services-details',
                btnText: 'View More',
            },
            {
                id: 3,
                image: require('../../assets/images/services/services.png'),
                title: 'IT-Services',
                desc: 'Wir unterstützen Dich gerne bei sämtlichen Anliegen zum vorhandenen IT-Betrieb oder stehen für Dich als externe IT-Abteilung zur Verfügung. In Projekten und der strategischen Planung bieten die erfahrenen IT-Experten unseres Teams eine umfassende Beratung.',
                link: '/services-details',
                btnText: 'View More',
            },
          {
            id: 4,
            image: require('../../assets/images/services/services-it-security.png'),
            title: 'IT-Sicherheit',
            desc: 'Wir erarbeiten eine Sicherheitsstrategie, die individuell auf Dein Unternehmen zugeschnitten ist. Unsere Sicherheitskonzepte garantieren dabei die Vertraulichkeit, Verfügbarkeit und Integrität Deiner Unternehmensdaten nach den aktuellsten Standards und darüber hinaus.',
            link: '/services-details',
            btnText: 'View More',
          },
          {
            id: 5,
            image: require('../../assets/images/services/services-cloud.png'),
            title: 'Cloud-Services',
            desc: 'Wir unterstützen unsere Kunden bei der Migration ihrer IT-Systeme in die Cloud und stellen sicher, dass diese sicher und zuverlässig laufen.',
            link: '/services-details',
            btnText: 'View More',
          },
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
			},
            768: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            1024: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
        },
    }),
})
</script>