<!--<template>
    <div class="team-area pt-100 pb-75">
        <div class="container">
            <div class="section-title section-style-two">
                <div class="section-bg-text">TEAM</div>
                <span>TEAM MEMBER</span>
                <h2>Our Expert IT Consultants</h2>
                <p>We are leading technology solutions providing company all over the world doing over 40 years lorem ipsum dolor sit amet.</p>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-3 col-sm-6">
                    <div class="single-team-card">
                        <div class="team-image" data-tilt>
                            <img src="../../assets/images/team/team-1.png" alt="image">

                            <ul class="team-social">
                                <li>
                                    <a href="https://www.facebook.com/EnvyTheme" target="_blank">
                                        <i class="ri-facebook-fill"></i>
                                    </a>
                                </li>

                                <li>
                                    <a href="https://twitter.com/?lang=en" target="_blank">
                                        <i class="ri-twitter-fill"></i>
                                    </a>
                                </li>
                                
                                <li>
                                    <a href="https://www.instagram.com/" target="_blank">
                                        <i class="ri-instagram-line"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="team-content">
                            <h3>Johny Smith</h3>
                            <span>President & CEO</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-team-card">
                        <div class="team-image" data-tilt>
                            <img src="../../assets/images/team/team-2.png" alt="image">

                            <ul class="team-social">
                                <li>
                                    <a href="https://www.facebook.com/EnvyTheme" target="_blank">
                                        <i class="ri-facebook-fill"></i>
                                    </a>
                                </li>

                                <li>
                                    <a href="https://twitter.com/?lang=en" target="_blank">
                                        <i class="ri-twitter-fill"></i>
                                    </a>
                                </li>
                                
                                <li>
                                    <a href="https://www.instagram.com/" target="_blank">
                                        <i class="ri-instagram-line"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="team-content">
                            <h3>Jennifer Walter</h3>
                            <span>Product Manager</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-team-card">
                        <div class="team-image" data-tilt>
                            <img src="../../assets/images/team/team-3.png" alt="image">

                            <ul class="team-social">
                                <li>
                                    <a href="https://www.facebook.com/EnvyTheme" target="_blank">
                                        <i class="ri-facebook-fill"></i>
                                    </a>
                                </li>

                                <li>
                                    <a href="https://twitter.com/?lang=en" target="_blank">
                                        <i class="ri-twitter-fill"></i>
                                    </a>
                                </li>
                                
                                <li>
                                    <a href="https://www.instagram.com/" target="_blank">
                                        <i class="ri-instagram-line"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="team-content">
                            <h3>Jems Rodrigez</h3>
                            <span>UI UX Designer</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-team-card">
                        <div class="team-image" data-tilt>
                            <img src="../../assets/images/team/team-4.png" alt="image">

                            <ul class="team-social">
                                <li>
                                    <a href="https://www.facebook.com/EnvyTheme" target="_blank">
                                        <i class="ri-facebook-fill"></i>
                                    </a>
                                </li>

                                <li>
                                    <a href="https://twitter.com/?lang=en" target="_blank">
                                        <i class="ri-twitter-fill"></i>
                                    </a>
                                </li>
                                
                                <li>
                                    <a href="https://www.instagram.com/" target="_blank">
                                        <i class="ri-instagram-line"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="team-content">
                            <h3>Deren Bravoo</h3>
                            <span>Web Developer</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>-->

<script>
export default {
    name: 'Team'
}
</script>