<template>
    <div id="about" class="about-area pb-75">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-wrap-image" data-tilt>
                        <img src="../../assets/images/about/locations.jpg" alt="image" data-aos="fade-down" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="about-wrap-content" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                        <span>Über uns</span>
                        <h3>Dein regionaler Partner rund um IT-Dienstleistungen</h3>
                        <p>Wir sind ein regionales IT-Dienstleistungsunternehmen, das sich auf die Bereitstellung von hochwertigen IT-Services für Unternehmen jeder Größe spezialisiert hat. Unser Team besteht aus erfahrenen IT-Experten, denen Deine individuellen Bedürfnisse und Vorstellungen am Herzen liegen und für die eine Orientierung am neuesten Stand der Technologie zur Selbstverständlichkeit gehört. Wir bieten Dir eine breite Palette an Dienstleistungen, die sich von der Beratung über die Implementierung bis hin zur laufenden Unterstützung und Wartung erstreckt. Unser Ziel ist es, regionale Unternehmen dabei zu unterstützen, ihre IT-Systeme auf den neuesten Stand zu bringen und Geschäftsprozesse zu optimieren. Wir sind stolz darauf, in Nordfriesland und Flensburg ansässig zu sein und unsere Dienstleistungen kundennah in der Region anbieten zu können.</p>
                    </div>
                </div>
            </div>

            <div class="about-inner-box">
                <div class="row justify-content-center">
                  <div class="col-lg-7 col-md-7">
                        <div class="single-about-card" data-aos="fade-down" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">
                            <h3>Unsere Mission</h3>
                            <p>Wir verfolgen das Ziel, unsere Region zu stärken und Unternehmen verlässlich und zukunftsorientiert in ihren digitalen Vorhaben zu unterstützen. Unser Team besticht durch Zuverlässigkeit, Effizienz, Bodenständigkeit und Kundenorientierung. Dabei steht eine offene und unkomplizierte Kommunikation für uns im Mittelpunkt. Unser Anspruch ist es, dass sich Unternehmen jeder Größe heute und auch in Zukunft auf unsere IT-Dienstleistungen verlassen können.</p>
                        </div>
                    </div>

                    <div class="col-lg-5 col-md-5">
                        <div class="single-about-card" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                            <div class="card-image" data-tilt>
                                <img src="../../assets/images/about/mission.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'About'
}
</script>