<template>
    <div class="blog-area pt-100 pb-100">
        <div class="container">
            <div class="section-title">
                <span>Blog</span>
                <h2>Read Our Blog To Get All Recent Tech <b>News</b></h2>
            </div>
            
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-card">
                        <div class="blog-image">
                            <router-link to="/single-blog-2">
                                <img src="../../assets/images/blog/blog-7.jpg" alt="image">
                            </router-link>

                            <div class="date">9th July, 2022</div>
                        </div>
                        <div class="blog-content">
                            <h3>
                                <router-link to="/single-blog-2">
                                    How Technology Dominate In The new World In 2022
                                </router-link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet conset sadipscing elitr sed diam nonumy eir m od tempor invidunt ut labore.</p>
                            <router-link to="/single-blog-2" class="blog-btn">View More</router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-card">
                        <div class="blog-image">
                            <router-link to="/single-blog-2">
                                <img src="../../assets/images/blog/blog-8.jpg" alt="image">
                            </router-link>

                            <div class="date">9th July, 2022</div>
                        </div>
                        <div class="blog-content">
                            <h3>
                                <router-link to="/single-blog-2">
                                    Top 10 Most Famous Technology Trend In 2022
                                </router-link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet conset sadipscing elitr sed diam nonumy eir m od tempor invidunt ut labore.</p>
                            <router-link to="/single-blog-2" class="blog-btn">View More</router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-card">
                        <div class="blog-image">
                            <router-link to="/single-blog-2">
                                <img src="../../assets/images/blog/blog-9.jpg" alt="image">
                            </router-link>

                            <div class="date">9th July, 2022</div>
                        </div>
                        <div class="blog-content">
                            <h3>
                                <router-link to="/single-blog-2">
                                    Open Source Job Report Show More Openings Fewer
                                </router-link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet conset sadipscing elitr sed diam nonumy eir m od tempor invidunt ut labore.</p>
                            <router-link to="/single-blog-2" class="blog-btn">View More</router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-card">
                        <div class="blog-image">
                            <router-link to="/single-blog-2">
                                <img src="../../assets/images/blog/blog-10.jpg" alt="image">
                            </router-link>

                            <div class="date">9th July, 2022</div>
                        </div>
                        <div class="blog-content">
                            <h3>
                                <router-link to="/single-blog-2">
                                    Tech Products That Makes Its Easier To Stay At Home
                                </router-link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet conset sadipscing elitr sed diam nonumy eir m od tempor invidunt ut labore.</p>
                            <router-link to="/single-blog-2" class="blog-btn">View More</router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-card">
                        <div class="blog-image">
                            <router-link to="/single-blog-2">
                                <img src="../../assets/images/blog/blog-11.jpg" alt="image">
                            </router-link>

                            <div class="date">9th July, 2022</div>
                        </div>
                        <div class="blog-content">
                            <h3>
                                <router-link to="/single-blog-2">
                                    Necessity May Give Us Your Best Virtual Court System
                                </router-link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet conset sadipscing elitr sed diam nonumy eir m od tempor invidunt ut labore.</p>
                            <router-link to="/single-blog-2" class="blog-btn">View More</router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-card">
                        <div class="blog-image">
                            <router-link to="/single-blog-2">
                                <img src="../../assets/images/blog/blog-12.jpg" alt="image">
                            </router-link>

                            <div class="date">9th July, 2022</div>
                        </div>
                        <div class="blog-content">
                            <h3>
                                <router-link to="/single-blog-2">
                                    How Technology Helps In The Education System In The World
                                </router-link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet conset sadipscing elitr sed diam nonumy eir m od tempor invidunt ut labore.</p>
                            <router-link to="/single-blog-2" class="blog-btn">View More</router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="pagination-area">
                        <router-link to="/blog-style-2" class="prev page-numbers">
                            <i class="ri-arrow-left-s-line"></i>
                        </router-link>
                        <span class="page-numbers current" aria-current="page">1</span>
                        <router-link to="/blog-style-2" class="page-numbers">2</router-link>
                        <router-link to="/blog-style-2" class="page-numbers">3</router-link>
                        <router-link to="/blog-style-2" class="next page-numbers">
                            <i class="ri-arrow-right-s-line"></i>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Blog'
}
</script>