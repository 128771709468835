<template>
    <div class="main-banner-area">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="main-banner-content">
                        <span data-aos="fade-right" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">IT-Dienstleistungen für Nordfriesland, Flensburg und Umgebung</span>
                        <h1 data-aos="fade-right" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">Moin Moin!</h1>
                        <p data-aos="fade-right" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">Wir sind ein Team aus der Region für die Region. Unsere Dienstleistungen umfassen die ganzheitliche Beratung, Implementierung, Wartung und Unterstützung von IT-Systemen sowie die Entwicklung von maßgeschneiderten Softwarelösungen. Unser Team aus erfahrenen IT-Experten und Softwareentwicklern arbeiten dabei eng mit Dir zusammen, um sämtliche Geschäftsprozesse optimal zu unterstützen und Deine IT-Systeme und Anwendungen stets auf dem neuesten Stand zu halten. Wenn dies Dein Interesse weckt, Du Fragen hast oder mehr über unsere Dienstleistungen erfahren möchtest, dann zögere nicht, uns zu kontaktieren.</p>
                        
                        <div class="banner-btn" data-aos="fade-right" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">
                            <a href="#contact" class="default-btn">Wir freuen uns, von Dir zu hören!</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="main-banner-image">
                        <img src="../../assets/images/main-banner/banner.png" alt="image" data-aos="fade-down" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>