<!--<template>
    <div class="choose-area pt-100 pb-75">
            <div class="container">
                <div class="section-title section-style-two">
                    <span>Ablauf</span>
                    <h2>So arbeiten sie mit uns</h2>
                    <p>Besonders größere Projekte benötigen einen sinnvollen Ablauf um einen reibungslosen Prozess sicherzustellen.</p>
                </div>

                <div class="row justify-content-center">
                    <div class="col-lg-3 col-sm-6">
                        <div class="single-choose-card" data-aos="fade-up" data-aos-delay="50" data-aos-duration="500" data-aos-once="true">
                            <div class="choose-image" data-tilt>
                                <router-link to="/services-details">
                                    <img src="../../assets/images/choose/choose-1.png" alt="image">
                                </router-link>

                                <div class="number">1</div>
                            </div>
                            <div class="choose-content">
                                <h3>
                                    <a href="#">Discussion</a>
                                </h3>
                                <p>Lorem ipsum dolor sit amet conset sadipscing elitr sed diam nonumy eirm od tempor invidunt ut labore.</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6">
                        <div class="single-choose-card" data-aos="fade-up" data-aos-delay="60" data-aos-duration="600" data-aos-once="true">
                            <div class="choose-image" data-tilt>
                                <router-link to="/services-details">
                                    <img src="../../assets/images/choose/choose-2.png" alt="image">
                                </router-link>

                                <div class="number">2</div>
                            </div>
                            <div class="choose-content">
                                <h3>
                                    <a href="#">Testing & Trying</a>
                                </h3>
                                <p>Lorem ipsum dolor sit amet conset sadipscing elitr sed diam nonumy eirm od tempor invidunt ut labore.</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6">
                        <div class="single-choose-card" data-aos="fade-up" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">
                            <div class="choose-image" data-tilt>
                                <router-link to="/services-details">
                                    <img src="../../assets/images/choose/choose-3.png" alt="image">
                                </router-link>

                                <div class="number">3</div>
                            </div>
                            <div class="choose-content">
                                <h3>
                                    <a href="#">Ideas & Concept</a>
                                </h3>
                                <p>Lorem ipsum dolor sit amet conset sadipscing elitr sed diam nonumy eirm od tempor invidunt ut labore.</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6">
                        <div class="single-choose-card" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                            <div class="choose-image" data-tilt>
                                <router-link to="/services-details">
                                    <img src="../../assets/images/choose/choose-4.png" alt="image">
                                </router-link>

                                <div class="number">4</div>
                            </div>
                            <div class="choose-content">
                                <h3>
                                    <a href="#">Execute & Install</a>
                                </h3>
                                <p>Lorem ipsum dolor sit amet conset sadipscing elitr sed diam nonumy eirm od tempor invidunt ut labore.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>-->

<script>
export default {
    name: 'ChooseUs'
}
</script>