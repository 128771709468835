<template>
    <footer class="footer-area with-black-background pt-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-3 col-md-6">
                    <div class="single-footer-widget" data-aos="fade-up" data-aos-delay="50" data-aos-duration="500" data-aos-once="true">
                        <div class="widget-logo">
                            <router-link to="/">
                                <img style="height: 128px;" src="../../assets/images/logo-lpits-white.png" alt="image">
                            </router-link>
                        </div>
                        <p>IT-Dienstleistungen für Nordfriesland, Flensburg und Umgebung</p>

                        <!--<ul class="widget-social">
                            <li>
                                <a href="https://www.facebook.com/EnvyTheme" target="_blank">
                                    <i class="ri-facebook-fill"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/?lang=en" target="_blank">
                                    <i class="ri-twitter-fill"></i>
                                </a>
                            </li>

                            <li>
                                <a href="https://www.youtube.com/" target="_blank">
                                    <i class="ri-youtube-fill"></i>
                                </a>
                            </li>

                            <li>
                                <a href="https://vimeo.com/" target="_blank">
                                    <i class="ri-vimeo-fill"></i>
                                </a>
                            </li>
                            
                            <li>
                                <a href="https://www.instagram.com/" target="_blank">
                                    <i class="ri-instagram-line"></i>
                                </a>
                            </li>
                        </ul>-->
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="single-footer-widget ps-5" data-aos="fade-up" data-aos-delay="60" data-aos-duration="600" data-aos-once="true">
                        <h3>Links</h3>

                        <ul class="quick-links">
                            <li><a href="#about">Über uns</a></li>
                            <li><a href="#services">Dienstleistungen</a></li>
                            <li><a href="#contact">Kontakt</a></li>
                            <li><a href="https://tickee.de" target="_blank">Tickee</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="single-footer-widget ps-5" data-aos="fade-up" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">
                        <h3>Rechtliches</h3>

                        <ul class="quick-links">
                            <li><router-link to="/impressum">Impressum</router-link></li>
                            <li><router-link to="/impressum">Datenschutz</router-link></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="single-footer-widget" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                        <h3>Bleibe auf dem laufenden!</h3>

                        <form class="newsletter-form" data-bs-toggle="validator">
                            <input type="email" class="input-newsletter" placeholder="Deine E-Mail Adresse" name="EMAIL">
    
                            <button type="submit" class="default-btn">Newsletter abonnieren</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="container">
                <div class="copyright-area-content">
                    <p>
                        &copy; {{currentYear}} LP IT Solutions, Dorfstraße 8b, 25885 Immenstedt
                    </p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    }
}
</script>