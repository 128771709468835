<template>
    <div class="terms-of-service-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12">
                    <div class="terms-of-service-content">
                      <h1>Impressum</h1>
                      <br/>
                      <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
                      <p>Leif Paisen<br />
                        LP IT Solutions<br />
                        Dorfstra&szlig;e 8b<br />
                        25885 Immenstedt</p>

                      <h2>Kontakt</h2>
                      <p>Telefon: +49 4843 230 981 0<br />
                        E-Mail: mail@leifpaisen.de</p>

                      <h2>Postadresse</h2>
                      <p>LP IT Solutions<br />
                        Lise-Meitner-Stra&szlig;e 2<br />
                        24941 Flensburg</p>
                      <h2>Redaktionell verantwortlich</h2>
                      <p>Leif Paisen</p>

                      <h2>EU-Streitschlichtung</h2>
                      <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

                      <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
                      <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

                      <p>Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Imprint'
}
</script>