<template>
    <div class="features-area pb-75">
        <div class="container">
            <div class="features-inner-box">
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md-6 pb-3" data-aos="fade-down" data-aos-delay="100" data-aos-duration="1000" data-aos-once="true">
                        <div style="width: 100%; height: 100%;" class="single-features-card">
                            <div class="features-image">
                                <router-link to="/services-details">
                                  <img src="../../assets/images/services/services-it-infra.png" alt="image">
                                </router-link>
                            </div>
                            <div class="content">
                                <h3>
                                  IT-Infrastruktur
                                </h3>
                                <p>Als IT-Dienstleister bieten wir Dir eine ganzheitliche IT-Infrastruktur-Betreuung. Dies umfasst die Ausarbeitung eines Konzeptes anhand Deiner individuellen Vorstellungen, die Bereitstellung von Hardware und Software sowie die Installation und fortlaufende Wartung unserer Systeme.</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 pb-3" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000" data-aos-once="true">
                        <div style="width: 100%; height: 100%;" class="single-features-card" data-tilt>
                            <div class="features-image" data-tilt>
                                <router-link to="/services-details">
                                    <img src="../../assets/images/services/services.png" alt="image">
                                </router-link>
                            </div>
                            <div class="content">
                                <h3>
                                  IT-Services
                                </h3>
                                <p>Wir unterstützen Dich gerne bei sämtlichen Anliegen zum vorhandenen IT-Betrieb oder stehen für Dich als externe IT-Abteilung zur Verfügung. In Projekten und der strategischen Planung bieten die erfahrenen IT-Experten unseres Teams eine umfassende Beratung.</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 pb-3" data-aos="fade-down" data-aos-delay="100" data-aos-duration="1000" data-aos-once="true">
                        <div style="width: 100%; height: 100%;" class="single-features-card" data-tilt>
                            <div class="features-image" data-tilt>
                                <router-link to="/services-details">
                                    <img src="../../assets/images/services/services-it-security.png" alt="image">
                                </router-link>
                            </div>
                            <div class="content">
                                <h3>
                                  IT-Sicherheit
                                </h3>
                                <p>Wir erarbeiten eine Sicherheitsstrategie, die individuell auf Dein Unternehmen zugeschnitten ist. Unsere Sicherheitskonzepte garantieren dabei die Vertraulichkeit, Verfügbarkeit und Integrität Deiner Unternehmensdaten nach den aktuellsten Standards und darüber hinaus.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Features'
}
</script>