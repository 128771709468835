<template>
    <div class="overview-area pt-5 pb-5">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-3 col-md-6">
                    <div class="overview-card">
                        <h3>Telefon</h3>
                        <span>
                            <a href="tel:+4948432309810">+49 4843 230 981 0</a>
                        </span>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="overview-card">
                        <h3>E-Mail Adresse</h3>
                        <span>
                            <a href="mailto:mail@leifpaisen.de">mail@leifpaisen.de</a>
                        </span>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="overview-card">
                        <h3>Notfälle</h3>
                        <span>
                            <a href="tel:+491719586622">+49 171 958 6622</a>
                        </span>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="overview-card">
                        <h3>Adresse</h3>
                        <span>Lise-Meitner-Straße 2, 24941 Flensburg</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Overview'
}
</script>