<template>
    <div class="blog-area pt-100 pb-100">
        <div class="container">
            <div class="section-title">
                <span>ARTICLEEE</span>
                <h2>Read Our Blog To Get All Recent Tech <b>News</b></h2>
            </div>

            <div class="blog-grid-sorting row align-items-center">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <form class="search-form">
                            <input type="search" class="search-field" placeholder="Search your products">
                            <button type="submit"><i class="ri-search-line"></i></button>
                        </form>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="select-box">
                            <select>
                                <option>Sort By Date</option>
                                <option>Popularity</option>
                                <option>Latest</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-12">
                    <div class="blog-card" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <div class="blog-image">
                                    <router-link to="/single-blog-1">
                                        <img src="../../assets/images/blog/blog-1.jpg" alt="image">
                                    </router-link>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="blog-content">
                                    <div class="date">9th July, 2022</div>
                                    <h3>
                                        <router-link to="/single-blog-1">
                                            How Technology Dominate In The new World In 2022
                                        </router-link>
                                    </h3>
                                    <p>Lorem ipsum dolor sit amet conset sadipscing elitr sed diam nonumy eir m od tempor invidunt ut labore.</p>
                                    <router-link to="/single-blog-1" class="blog-btn">View More</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-6 col-md-12">
                    <div class="blog-card" data-aos="fade-up" data-aos-delay="890" data-aos-duration="900" data-aos-once="true">
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <div class="blog-image">
                                    <router-link to="/single-blog-1">
                                        <img src="../../assets/images/blog/blog-2.jpg" alt="image">
                                    </router-link>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="blog-content">
                                    <div class="date">7th July, 2022</div>
                                    <h3>
                                        <router-link to="/single-blog-1">
                                            Top 10 Most Famous Technology Trend In 2022
                                        </router-link>
                                    </h3>
                                    <p>Lorem ipsum dolor sit amet conset sadipscing elitr sed diam nonumy eir m od tempor invidunt ut labore.</p>
                                    <router-link to="/single-blog-1" class="blog-btn">View More</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="blog-card" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <div class="blog-image">
                                    <router-link to="/single-blog-1">
                                        <img src="../../assets/images/blog/blog-3.jpg" alt="image">
                                    </router-link>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="blog-content">
                                    <div class="date">9th July, 2022</div>
                                    <h3>
                                        <router-link to="/single-blog-1">
                                            Open Source Job Report Show More Openings Fewer
                                        </router-link>
                                    </h3>
                                    <p>Lorem ipsum dolor sit amet conset sadipscing elitr sed diam nonumy eir m od tempor invidunt ut labore.</p>
                                    <router-link to="/single-blog-1" class="blog-btn">View More</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-6 col-md-12">
                    <div class="blog-card" data-aos="fade-up" data-aos-delay="90" data-aos-duration="900" data-aos-once="true">
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <div class="blog-image">
                                    <router-link to="/single-blog-1">
                                        <img src="../../assets/images/blog/blog-4.jpg" alt="image">
                                    </router-link>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="blog-content">
                                    <div class="date">7th July, 2022</div>
                                    <h3>
                                        <router-link to="/single-blog-1">
                                            Tech Products That Makes Its Easier To Stay At Home
                                        </router-link>
                                    </h3>
                                    <p>Lorem ipsum dolor sit amet conset sadipscing elitr sed diam nonumy eir m od tempor invidunt ut labore.</p>
                                    <router-link to="/single-blog-1" class="blog-btn">View More</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="blog-card" data-aos="fade-up" data-aos-delay="90" data-aos-duration="900" data-aos-once="true">
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <div class="blog-image">
                                    <router-link to="/single-blog-1">
                                        <img src="../../assets/images/blog/blog-5.jpg" alt="image">
                                    </router-link>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="blog-content">
                                    <div class="date">7th July, 2022</div>
                                    <h3>
                                        <router-link to="/single-blog-1">
                                            Necessity May Give Us Your Best Virtual Court System
                                        </router-link>
                                    </h3>
                                    <p>Lorem ipsum dolor sit amet conset sadipscing elitr sed diam nonumy eir m od tempor invidunt ut labore.</p>
                                    <router-link to="/single-blog-1" class="blog-btn">View More</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="blog-card" data-aos="fade-up" data-aos-delay="90" data-aos-duration="900" data-aos-once="true">
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <div class="blog-image">
                                    <router-link to="/single-blog-1">
                                        <img src="../../assets/images/blog/blog-6.jpg" alt="image">
                                    </router-link>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="blog-content">
                                    <div class="date">7th July, 2022</div>
                                    <h3>
                                        <router-link to="/single-blog-1">
                                            How Technology Helps In The Education System In The World
                                        </router-link>
                                    </h3>
                                    <p>Lorem ipsum dolor sit amet conset sadipscing elitr sed diam nonumy eir m od tempor invidunt ut labore.</p>
                                    <router-link to="/single-blog-1" class="blog-btn">View More</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="pagination-area">
                        <router-link to="/blog-style-1" class="prev page-numbers">
                            <i class="ri-arrow-left-s-line"></i>
                        </router-link>
                        <span class="page-numbers current" aria-current="page">1</span>
                        <router-link to="/blog-style-1" class="page-numbers">2</router-link>
                        <router-link to="/blog-style-1" class="page-numbers">3</router-link>
                        <router-link to="/blog-style-1" class="next page-numbers">
                            <i class="ri-arrow-right-s-line"></i>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        <div class="blog-shape-1">
            <img src="../../assets/images/blog/blog-shape-1.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'Blog'
}
</script>