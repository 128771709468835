<template>
  <div id="contact" class="talk-area ptb-100">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="talk-image" data-tilt>
            <img src="../../assets/images/about/help.png" alt="image">
          </div>
        </div>

        <div class="col-lg-6 col-md-12">
          <div class="talk-content">
            <span>Lass uns sprechen!</span>
            <h3>Können wir Dir helfen?</h3>
            <p>Nutze gerne folgendes Kontaktformular oder rufe uns einfach an!</p>

            <form v-on:submit.prevent="submitForm" id="contactFormTwo">

              <span v-if="error.length" style="color: red">{{ error }}</span>
              <span v-if="success.length">{{ success }}</span>

              <div class="row">
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <input v-model="form.name" type="text" name="name" class="form-control" placeholder="Dein Name">
                  </div>
                </div>

                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <input v-model="form.email" type="email" name="email" class="form-control" placeholder="Deine E-Mail Adresse">
                  </div>
                </div>

                <div class="col-lg-12 col-md-12">
                  <div class="form-group">
                    <textarea v-model="form.message" name="message" class="form-control" cols="30" rows="6"
                              placeholder="Deine Nachricht..."></textarea>
                  </div>
                </div>

                <div class="col-lg-12 col-md-12">
                  <button type="submit" class="default-btn">Absenden<span></span></button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "axios";

export default {
  name: 'Talk',
  data() {
    return {
      error: "",
      success: "",
      form: {
        name: '',
        email: '',
        message: '',
      }
    }
  },
  methods: {
    submitForm: function(e) {

      this.error = "";
      this.success = "";

      if (!this.form.name) {
        this.error = "Bitte gebe einen Namen an";
      } else if (!this.form.email) {
        this.error = "Bitte gebe eine E-Mail Adresse an";
      } else if (!this.form.message) {
        this.error = "Es wurde keine Nachricht eingegeben.";
      } else {
        axios.post('/contact.php', this.form)
            .then((res) => {
              if (res.data === 'success') {
                this.success = "Vielen dank für deine Nachricht. Wir melden uns schnellstmöglich zurück!"
              } else {
                this.error = "Beim absenden des Formulars ist ein Fehler aufgetreten. Bitte versuche es später erneut oder kontaktiere uns direkt via E-Mail: mail@leifpaisen.de"
              }
            })
            .catch(() => {
              this.error = "Beim absenden des Formulars ist ein Fehler aufgetreten. Bitte versuche es später erneut oder kontaktiere uns direkt via E-Mail: mail@leifpaisen.de"
            }).finally(() => {
          //Perform action in always
        });
      }

      e.preventDefault();

    }
  }
}
</script>